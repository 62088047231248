import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import news10pic1 from '../assets/images/815.jpg'
import news10pic2 from '../assets/images/8152.jpg'
import news10pic3 from '../assets/images/8153.jpg'
import ios from '../assets/images/app-store.svg'
import google from '../assets/images/google-play.svg'
const News10 = props => (
  <Layout>
    <Helmet>
      <title>【 公告｜單次入場、會籍收費調整 】​</title>
      <meta name="description" content="三健客收費調整" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>
				【 公告｜單次入場
              <br />
              、會籍收費調整 】
            </h1>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news10pic1} alt="" />
                </span>
              </div>
            </div>
          </div>
          <p>
			 三健客從 2019 開幕到現在即將滿 4 年<br/>	
從一開始的板橋店到後來的永和店<br/>
非常感謝一路以來各位健客的支持
          </p>
          <p>為了持續維護大家的運動權益<br/>
並持續提供舒適且高 C/Ｐ值的訓練場館 💪🏻<br/><h2>八月起將調整 #入場收費</h2>
</p>
          <p>
			 \ 8/1 起，永和店收費方式調整如下 /

          </p>
          <h3>
			 🏋🏻‍♂️全時段 $1,088<br/>
🏋🏻‍♂️單次計費 $150<br/>
🏋🏻‍♂️分鐘計費 $1.2/分
			 </h3>
			 <h3>💡注意事項</h3>
			 <p>
1. 銀卡不再開放購買。原銀卡會員不受影響，可繼續使用。<br/>
2. 以分計費無收費上限，建議健客們能根據自己的訓練時間選擇最適合自己的方案。<br/>
​
如有任何問題也歡迎私訊我們為你解答 💬</p>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news10pic2} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={news10pic3} alt="" />
                </span>
              </div>
            </div>
          </div>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/3muscler/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/3musclers/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
				<li>
          <a
            href="https://apps.apple.com/tw/app/3musclers-%E4%B8%89%E5%81%A5%E5%AE%A2%E5%81%A5%E8%BA%AB%E4%BF%B1%E6%A8%82%E9%83%A8/id1486517436"
            style={{top:'10px'}}
				className='icon svg'
          >
            <img src={ios} alt='ios'/>
          </a>
        </li>
        <li>
          <a
            href="https://play.google.com/store/apps/details?id=com.appworkout.fitbutler.threemusclers&hl=zh_TW"
            style={{top:'10px'}}
				className='icon svg'
          >
            <img src={google} alt='google'/>
          </a>
        </li>
          </ul>
          <iframe
            src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2F&width=450&layout=standard&action=like&size=small&share=true&height=35&appId=3212226998840909"
            width="450"
            height="35"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allow="encrypted-media"
          ></iframe>
        </div>
      </section>
    </div>
  </Layout>
)

export default News10
